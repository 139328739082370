import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Accordion from '../components/GlodalComponents/Accordion/Accordion';
import TableTabs from '../components/AccountsPage/TableTabs/TableTabs';
import Tabs from '../components/AccountsPage/Tabs/Tabs';
import TextGrid from '../components/AccountsPage/TextGrid/TextGrid';

function AccountsPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Esmoris · Account types</title>
        <meta name="title" content="Choose the Right Account Type for You and Start Trading on Esmoris" />
        <meta property="og:title" content="Choose the Right Account Type for You and Start Trading on Esmoris" />
        <meta property="twitter:title" content="Choose the Right Account Type for You and Start Trading on Esmoris" />

        <meta name="description" content="Take advantage of our Beginner, Experience, Raw Spread, and Pro accounts to enhance your trading potential and increase your earnings. Select the account type that best suits your needs and start trading today!" />
        <meta property="og:description" content="Take advantage of our Beginner, Experience, Raw Spread, and Pro accounts to enhance your trading potential and increase your earnings. Select the account type that best suits your needs and start trading today!" />
        <meta property="twitter:description" content="Take advantage of our Beginner, Experience, Raw Spread, and Pro accounts to enhance your trading potential and increase your earnings. Select the account type that best suits your needs and start trading today!" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='account-page ovf-hidden'>
        <Header />
        <TableTabs title='acc.first.title' text='acc.first.text' tabs='acc.first.list' />
        <Tabs />
        <div className='container'><Accordion title='acc.third.list' /></div>
        <TextGrid />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccountsPage;
